import React from "react";
import {
  Mail,
  Phone,
  MapPin,
  Facebook,
  Instagram,
  ArrowUpRight,
  Heart,
  Youtube,
} from "lucide-react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-gray-300">
      {/* Main Footer */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-6">
            <div className="flex items-center">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Fmb_logo-500.png?alt=media&token=b4ad37e7-18c1-4e58-905a-a5ef50906dcf"
                alt="MatuByte Logo"
                className="h-10 w-auto"
              />
              <span className="ml-2 text-xl font-bold text-white">
                MatuByte
              </span>
            </div>
            <p className="text-sm">
              Transformamos ideas en soluciones digitales innovadoras. Expertos
              en desarrollo de software y aplicaciones web personalizadas.
            </p>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61568347402857"
                className="hover:text-white transition-colors"
              >
                <Facebook size={20} />
              </a>
              <a
                href="https://instagram.com"
                className="hover:text-white transition-colors"
              >
                <Instagram size={20} />
              </a>
              <a
                href="https://instagram.com"
                className="hover:text-white transition-colors"
              >
                <Youtube size={20} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-6">
              Enlaces Rápidos
            </h3>
            <ul className="space-y-4">
              <li>
                <Link
                  to="/"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Inicio <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/services"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Servicios <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/tecnologias"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Tecnologías <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/projects"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Portafolio <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/contact"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Contacto <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-6">Servicios</h3>
            <ul className="space-y-4">
              <li>
                <Link
                  to="/matubyte/services/software/development"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Desarrollo Web <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/services/mobile/development"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Aplicaciones Móviles{" "}
                  <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/services/business"
                  className="hover:text-white transition-colors flex items-center"
                >
                  E-commerce <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/services/uxui-design"
                  className="hover:text-white transition-colors flex items-center"
                >
                  UI/UX Design <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
              <li>
                <Link
                  to="/matubyte/services/it/consulting"
                  className="hover:text-white transition-colors flex items-center"
                >
                  Consultoría IT <ArrowUpRight size={14} className="ml-1" />
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-6">Contacto</h3>
            <ul className="space-y-4">
              <li className="flex items-center">
                <Mail size={20} className="mr-2" />
                <a
                  href="mailto:contacto@matubyte.online"
                  className="hover:text-white transition-colors"
                >
                  contacto@matubyte.online
                </a>
              </li>
              <li className="flex items-center">
                <Phone size={20} className="mr-2" />
                <a
                  href="tel:+1234567890"
                  className="hover:text-white transition-colors"
                >
                  +573023580862
                </a>
              </li>
              <li className="flex items-start">
                <MapPin size={20} className="mr-2 mt-1" />
                <span>
                  Carrea 24c #42a-138
                  <br />
                  Cali, Colombia
                  <br />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-800">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex items-center space-x-4">
              <span>© {currentYear} MatuByte.</span>
              <a href="#" className="hover:text-white transition-colors">
                Política de Privacidad
              </a>
              <a href="#" className="hover:text-white transition-colors">
                Términos de Servicio
              </a>
            </div>
            <div className="flex items-center text-sm">
              Hecho con <Heart size={16} className="mx-1 text-red-500" /> en
              MatuByte
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
