import React from "react";
import { Briefcase, Code, Monitor, Smartphone } from "lucide-react"; // Iconos de ejemplo
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HerosServices from "../heros/HerosServices";

const servicesData = [
  {
    id: 1,
    icon: <Briefcase className="w-10 h-10 text-blue-600" />,
    title: "Consultoría de Negocios",
    description:
      "Ayudamos a tu empresa a desarrollar una estrategia efectiva para alcanzar tus objetivos de negocio.",
    link: "/matubyte/services/business",
  },
  {
    id: 2,
    icon: <Code className="w-10 h-10 text-green-600" />,
    title: "Desarrollo de Software",
    description:
      "Desarrollamos soluciones a medida para mejorar la eficiencia y el rendimiento de tu negocio.",
    link: "/matubyte/services/software/development",
  },
  {
    id: 3,
    icon: <Monitor className="w-10 h-10 text-purple-600" />,
    title: "Diseño UX/UI",
    description:
      "Creamos experiencias de usuario intuitivas y atractivas para tus aplicaciones web y móviles.",
    link: "/matubyte/services/uxui-design",
  },
  {
    id: 4,
    icon: <Smartphone className="w-10 h-10 text-orange-600" />,
    title: "Desarrollo de Apps Móviles",
    description:
      "Construimos aplicaciones móviles nativas y multiplataforma para iOS y Android.",
    link: "/matubyte/services/mobile/development",
  },
];

const Services = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar/>
      <HerosServices/>
      <div className="py-16 px-8">
        <div className="max-w-6xl mx-auto text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900">
            Nuestros Servicios
          </h2>
          <p className="text-lg text-gray-600 mt-4">
            Ofrecemos una amplia gama de servicios para satisfacer las
            necesidades de tu empresa.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {servicesData.map((service) => (
            <div
              key={service.id}
              className="bg-white rounded-xl shadow-lg p-8 transition-transform transform hover:scale-105"
            >
              <div className="flex items-center justify-center mb-4">
                {service.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600 mb-4">{service.description}</p>
              <Link
                to={service.link}
                className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-200"
              >
                Más Información
              </Link>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Services;
