import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronDown } from 'lucide-react';

const HerosContacto = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section
      id="inicio"
      className="relative bg-cover bg-center bg-fixed text-white min-h-screen flex items-center"
      style={{ 
        backgroundImage: "url('https://numdea.com/wp-content/uploads/2020/03/definicion-servicios-1280x720.jpg')",
      }}
    >
      {/* Overlay con gradiente */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50" />

      <div className="relative w-full py-20">
        <div className="max-w-6xl mx-auto px-4">
          <div className={`space-y-8 transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'} transition-all duration-1000 ease-out`}>
            {/* Texto pequeño animado */}
            <p className="text-blue-300 font-medium tracking-wider uppercase text-sm md:text-base 
              animate-[slideIn_0.5s_ease-out] overflow-hidden">
              Contáctanos y agenda una cita
            </p>  

            {/* Título principal con efecto de aparición por palabras */}
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold tracking-tight leading-tight">
              {'Contáctanos'.split(' ').map((word, i) => (
                <span
                  key={i}
                  className={`inline-block transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'} transition-all duration-700`}
                  style={{ transitionDelay: `${i * 100}ms` }}
                >
                  {word}{' '}
                </span>
              ))}
              <span className="text-blue-300 inline-block transform hover:scale-105 transition-transform duration-300">
                Resuelve tus dudas en minuto
              </span>
            </h1>

            {/* Descripción con efecto de fade */}
            <p className={`text-xl md:text-2xl max-w-2xl leading-relaxed text-gray-200
              transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              transition-all duration-700 delay-300`}>
              En <span className="text-blue-300 font-semibold">MatuByte</span>, 
              ofrecemos contactos con una respuesta super rápida, para que así no tengas que esperar.
            </p>

            {/* Botones con efectos hover mejorados */}
            <div className={`flex flex-col sm:flex-row gap-4 pt-6
              transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              transition-all duration-700 delay-500`}>
              <Link
                to="/"
                className="group inline-flex items-center px-8 py-4 text-lg font-medium 
                  bg-blue-600 text-white rounded-lg 
                  transform hover:scale-105 hover:bg-blue-700
                  transition-all duration-300 ease-out
                  shadow-lg hover:shadow-blue-500/50"
              >
                Comenzar Proyecto
                <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link
                to="/matubyte/services"
                className="group inline-flex items-center px-8 py-4 text-lg font-medium 
                  border-2 border-white text-white rounded-lg 
                  hover:bg-white hover:text-blue-600
                  transform hover:scale-105
                  transition-all duration-300 ease-out"
              >
                Conocer Más
                <ChevronDown className="ml-2 transform group-hover:translate-y-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>

        {/* Onda decorativa mejorada */}
        <div className="absolute bottom-0 left-0 right-0 overflow-hidden">
          <svg
            className="w-full h-24 transform translate-y-1"
            viewBox="0 0 1440 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-white"
              d="M0,32L48,37.3C96,43,192,53,288,58.7C384,64,480,64,576,58.7C672,53,768,43,864,37.3C960,32,1056,32,1152,37.3C1248,43,1344,53,1392,58.7L1440,64L1440,74L1392,74C1344,74,1248,74,1152,74C1056,74,960,74,864,74C768,74,672,74,576,74C480,74,384,74,288,74C192,74,96,74,48,74L0,74Z"
            >
              <animate
                attributeName="d"
                dur="5s"
                repeatCount="indefinite"
                values="M0,32L48,37.3C96,43,192,53,288,58.7C384,64,480,64,576,58.7C672,53,768,43,864,37.3C960,32,1056,32,1152,37.3C1248,43,1344,53,1392,58.7L1440,64L1440,74L1392,74C1344,74,1248,74,1152,74C1056,74,960,74,864,74C768,74,672,74,576,74C480,74,384,74,288,74C192,74,96,74,48,74L0,74Z;
                       M0,42L48,37.3C96,33,192,43,288,48.7C384,54,480,54,576,48.7C672,43,768,33,864,37.3C960,42,1056,42,1152,37.3C1248,33,1344,43,1392,48.7L1440,54L1440,74L1392,74C1344,74,1248,74,1152,74C1056,74,960,74,864,74C768,74,672,74,576,74C480,74,384,74,288,74C192,74,96,74,48,74L0,74Z"
              />
            </path>
          </svg>
        </div>
      </div>

      {/* Añadir estilos globales para la animación de slideIn */}
      <style jsx global>{`
        @keyframes slideIn {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
    </section>
  );
};

export default HerosContacto;