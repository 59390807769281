import React, { useEffect, useState } from "react";
import {
  ArrowRight,
  Code,
  Globe,
  Zap,
  Users,
  Shield,
  ChevronRight,
  Mail,
  Menu,
  X,
  Phone,
  MapPin,
  Calendar,
  Star,
  MessageCircle,
  Send,
  WhatsApp,
  ChevronLeft,
  ChevronUp,
} from "lucide-react";
import { FaWhatsapp } from "react-icons/fa";
import Footer from "../components/Footer";
import AppointmentScheduler from "../components/AppointmentScheduler";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("opacity-100");
            entry.target.classList.remove("opacity-0", "translate-y-10");
          }
        });
      },
      { threshold: 0.1 }
    );

    document
      .querySelectorAll(".animate-on-scroll")
      .forEach((el) => observer.observe(el));

    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const technologies = [
    {
      name: "React",
      logo: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
    },
    {
      name: "Node.js",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Node.js_logo_2015.svg/2560px-Node.js_logo_2015.svg.png",
    },
    {
      name: "Python",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1200px-Python-logo-notext.svg.png",
    },
    {
      name: "JavaScript",
      logo: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
    },
    {
      name: "TypeScript",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcr9ErjixrOB4brfXICewuiMmzsvkUJ4jlHg&s",
    },
    {
      name: "MongoDB",
      logo: "https://cdn.iconscout.com/icon/free/png-256/free-mongodb-logo-icon-download-in-svg-png-gif-file-formats--wordmark-programming-langugae-freebies-pack-logos-icons-1175140.png?f=webp",
    },
    {
      name: "PostgreSQL",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/640px-Postgresql_elephant.svg.png",
    },
    {
      name: "Docker",
      logo: "https://cdn.worldvectorlogo.com/logos/docker.svg",
    },
    {
      name: "Java",
      logo: "https://upload.wikimedia.org/wikipedia/de/thumb/e/e1/Java-Logo.svg/1200px-Java-Logo.svg.png",
    },
    {
      name: "Android",
      logo: "https://images.vexels.com/media/users/3/139556/isolated/svg/1718a076e29822051df8bcf8b5ce1124.svg",
    },
    {
      name: "WordPress",
      logo: "https://download.logo.wine/logo/WordPress.com/WordPress.com-Logo.wine.png",
    },
  ];

  const portfolio = [
    {
      title: "Fymapp",
      description: "Plataforma de gestión de inventario",
      image:
        "https://scontent.fclo5-1.fna.fbcdn.net/v/t39.30808-6/455695023_122166735980186389_679842006379188988_n.jpg?stp=dst-jpg_s960x960_tt6&_nc_cat=101&ccb=1-7&_nc_sid=cc71e4&_nc_eui2=AeH4kjjWJiALvnhk-pz18stebu4Sgj_e4TRu7hKCP97hNFXxuviWYA27X22uC4HuDLO7npDA36cGZ_1LnTy-GKW_&_nc_ohc=hvED7N4mTDoQ7kNvgF99r3i&_nc_zt=23&_nc_ht=scontent.fclo5-1.fna&_nc_gid=Au1vj0rZgVKJRB1NS2ajHdR&oh=00_AYDme0Isfjja8HXLgs6wYpCiywW-lMxBnougO_7lLldEXw&oe=67499962",
      tech: ["React", "Node.js", "MongoDB"],
      link: "https://fymappsoftware.online/",
    },
    {
      title: "DeportivosPro",
      description: "Sistema de consejos deportivos",
      image:
        "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2",
      tech: ["Python", "PostgreSQL", "Docker"],
      link: "https://deportivospro.online/",
    },
    {
      title: "Crea y viste",
      description: "Aplicación móvil para crear y diseñar ropa",
      image:
        "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Fcrea%20y%20viste%20logo.jpg?alt=media&token=2fd85ce1-1e30-4a4c-b340-4000aed76463",
      tech: ["React Native", "Firebase", "Google Maps"],
      link: "https://crea-y-viste.web.app/",
    },
    {
      title: "La MakyStore",
      description: "Tienda de ropa con sentido social",
      image:
        "https://lamakystore.shop/wp-content/uploads/2024/10/Logo_maky-removebg.png",
      tech: ["WordPress", "PayU", "PostgreSQL"],
      link: "https://lamakystore.shop/",
    },
    {
      title: "Econo Tacna",
      description: "Tienda, Bebidas, Alimentos etc.",
      image: "https://econotacna.com/wp-content/uploads/2024/11/Logo.jpg",
      tech: ["WordPress", "PHP", "SQL"],
      link: "https://econotacna.com/",
    },
  ];

  const testimonials = [
    {
      name: "Carlos Rodríguez",
      company: "TechCorp",
      image:
        "https://media.licdn.com/dms/image/D4E03AQFAubykIZfIwA/profile-displayphoto-shrink_400_400/0/1678795079391?e=2147483647&v=beta&t=-aX0K9DxeagkboXiGhetr_aG_hzHP2NCluyorT3EOlc",
      text: "Excelente trabajo y profesionalismo. Entregaron el proyecto antes de lo esperado.",
    },
    {
      name: "Ana Martínez",
      company: "Digital Solutions",
      image: "https://avatars.githubusercontent.com/u/33347782?v=4",
      text: "La mejor decisión fue trabajar con MatuByte. Su equipo es muy profesional.",
    },
    {
      name: "Diego López",
      company: "StartupX",
      image: "https://avatars.githubusercontent.com/u/227258?v=4",
      text: "Increíble atención al detalle y soporte técnico excepcional.",
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen">
      <Navbar />
      <HeroSection />
      {/* Services Section */}
      <section id="servicios" className="py-20 bg-white">
        {/* [El contenido de servicios existente se mantiene igual] */}
      </section>

      {/* Technologies Section */}
      <section id="tecnologias" className="py-20 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-16 animate-on-scroll opacity-0 transition-all duration-1000">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
              Tecnologías
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Trabajamos con las últimas tecnologías del mercado
            </p>
          </div>

          <div className="flex overflow-hidden space-x-8 animate-on-scroll opacity-0">
            <div className="flex space-x-8 animate-scroll">
              {[...technologies, ...technologies].map((tech, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center min-w-[120px]"
                >
                  <img
                    src={tech.logo}
                    alt={tech.name}
                    className="w-16 h-16 object-contain"
                  />
                  <span className="mt-2 text-sm text-gray-600">
                    {tech.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section id="portafolio" className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-16 animate-on-scroll opacity-0 transition-all duration-1000">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
              Nuestro Portafolio
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Conoce algunos de nuestros proyectos más destacados
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {portfolio.map((project, index) => (
              <a
                key={index}
                href={project.link} // El link específico del proyecto
                target="_blank" // Abre en una nueva pestaña (opcional)
                rel="noopener noreferrer" // Seguridad para enlaces externos
                className="bg-white rounded-lg shadow-lg overflow-hidden animate-on-scroll opacity-0 transition-all duration-1000"
              >
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900">
                    {project.title}
                  </h3>
                  <p className="mt-2 text-gray-600">{project.description}</p>
                  <div className="mt-4 flex flex-wrap gap-2">
                    {project.tech.map((tech, techIndex) => (
                      <span
                        key={techIndex}
                        className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonios" className="py-20 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-16 animate-on-scroll opacity-0 transition-all duration-1000">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
              Testimonios
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Lo que nuestros clientes dicen sobre nosotros
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg animate-on-scroll opacity-0 transition-all duration-1000"
              >
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div className="ml-4">
                    <h3 className="font-bold text-gray-900">
                      {testimonial.name}
                    </h3>
                    <p className="text-gray-600 text-sm">
                      {testimonial.company}
                    </p>
                  </div>
                </div>
                <p className="text-gray-700">{testimonial.text}</p>
                <div className="mt-4 flex text-yellow-400">
                  <Star size={20} />
                  <Star size={20} />
                  <Star size={20} />
                  <Star size={20} />
                  <Star size={20} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Floating WhatsApp Button */}
      <a
        href="https://wa.me/573023580862"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-24 right-6 z-50 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors"
      >
        <FaWhatsapp size={24} />
      </a>

      {/* Scroll to Top Button */}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 z-50 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition-colors"
        >
          <ChevronUp size={24} />
        </button>
      )}
      <section id="agendar">
        <AppointmentScheduler />
      </section>
      <Footer />
    </div>
  );
};

export default HomePage;
